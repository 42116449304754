const checkPermissions = (item) => {
	let currentUser = JSON.parse(localStorage.getItem("userData"));
	let permissions = currentUser.permissions;
	let roles = currentUser.roles;
	if (roles == "super_admin" || roles == "owner") {
		return true;
	} else {
		if (item.roles || item.permissions) {
			if (item.roles && containsAny(roles, item.roles)) {
				return true;
			} else if (
				item.permissions &&
				containsAny(permissions, item.permissions)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
const containsAny = (array1, array2) => {
	return array1.some((e) => array2.indexOf(e) >= 0);
};

export default checkPermissions;
