<template>
	<li
		v-if="checkPermissions(item)"
		class="nav-item"
		:class="{
			active: isActive,
			disabled: item.disabled,
		}"
	>
		<b-link v-bind="linkProps" class="d-flex align-items-center">
			<feather-icon :icon="item.icon || 'CircleIcon'" />
			<span class="menu-title text-truncate">{{ t(item.title) }}</span>
			<b-badge
				v-if="item.tag"
				pill
				:variant="item.tagVariant || 'primary'"
				class="mr-1 ml-auto"
			>
				{{ item.tag }}
			</b-badge>
		</b-link>
	</li>
</template>

<script>
import checkPermissions from "@core/libs/permission";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";

export default {
	components: {
		BLink,
		BBadge,
	},
	mixins: [mixinVerticalNavMenuLink],
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
			props.item
		);
		const { t } = useI18nUtils();

		return {
			isActive,
			linkProps,
			updateIsActive,

			// ACL
			checkPermissions,

			// i18n
			t,
		};
	},
};
</script>
