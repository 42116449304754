import router from "@/router";
import ProjectIcon from "@core/components/project-icon/ProjectIcon.vue";

export default [
	{
		title: "Purchase",
		route: "apps-purchase",
		icon: "CalendarIcon",
		roles: ["admin", "owner"],
	},
	{
		title: "Inventory",
		route: "apps-inventory",
		icon: "InfoIcon",
		roles: ["admin", "owner"],
	},
	{
		title: "Expense Entry",
		route: "apps-expense-list",
		icon: "UsersIcon",
		roles: ["admin", "owner"],
	},
	// {
	// 	title: "Current Batch",
	// 	route: "",
	// 	icon: "CalendarIcon",
	// },
	{
		title: "Action list",
		route: "apps-action-list",
		icon: "CalendarIcon",
		roles: ["admin", "owner"],
	},
	{
		title: "Alarm",
		route: "apps-alarm",
		icon: "BellIcon",
		roles: ["admin", "owner"],
	},
	{
		title: "Machine",
		icon: "FileIcon",
		roles: ["admin", "owner"],
		children: [
			{
				title: "Setter",
				route: "apps-machine-setter",
			},
			{
				title: "Hatcher",
				route: "apps-machine-hatcher",
			},
		],
	},
	{
		title: "Hatch Summary",
		route: "apps-hatchSummary-list",
		icon: "CalendarIcon",
		roles: ["admin", "owner"],
	},
	// {
	//   title: "Hatch Summary",
	//   icon: "FileIcon",
	//   children: [
	//     {
	//       title: "Setter",
	//       route: "apps-hatchSummary-setter-list",
	//     },
	//     {
	//       title: "Hatcher",
	//       route: "apps-hatchSummary-hatcher-list",
	//     },
	//   ],
	// },
	// {
	//   title: "Reports",
	//   route: "apps-reports",
	//   icon: "CalendarIcon",
	//   roles: ["admin", "owner"],
	// },
	{
		title: "Reports",
		icon: "CalendarIcon",
		roles: ["admin", "owner"],
		children: [
			{
				title: "Internal Report",
				route: "apps-reports-internal",
			},
			{
				title: "Economy Report",
				route: "apps-reports-economy",
			},
		],
	},
	{
		title: "Sale",
		icon: "CalendarIcon",
		roles: ["admin", "owner"],
		children: [
			{
				title: "Post Hatch Mortality",
				route: "apps-sale-hatch-mortality",
			},
			{
				title: "Chick Sale",
				route: "apps-sale-chick-sale",
			},
			{
				title: "Other Sale",
				route: "apps-sale-other-sale",
			},
		],
	},
	// {
	// 	title: "Users",
	// 	icon: "UserIcon",
	// 	roles: ["admin"],
	// 	children: [
	// 		{
	// 			title: "Manager",
	// 			route: "apps-manager-list",
	// 		},
	// 		{
	// 			title: "AssistantManager",
	// 			route: "apps-assistant-list",
	// 		},
	// 		{
	// 			title: "Supervisor",
	// 			route: "apps-supervisor-list",
	// 		},
	// 	],
	// },
	{
		title: "Manage Device",
		route: "apps-device-machine-list",
		icon: "UsersIcon",
	},
	{
		title: "Settings",
		route: "apps-settings",
		icon: "UsersIcon",
		roles: ["admin", "owner"],
	},
];

// router.beforeEach((to, from, next) => {
// 	if (to.matched.some((data) => data.meta.permissions)) {
// 		if (record.meta.permissions) {
// 		}
// 	}
// });
