import router from "@/router";
import ProjectIcon from "@core/components/project-icon/ProjectIcon.vue";

export default [
	{
		title: "Company",
		route: "apps-company-detail",
		icon: "UsersIcon",
		roles: ["super_admin"],
	},
	// {
	// 	title: "Users",
	// 	icon: "UserIcon",
	// 	roles: ["super_admin"],
	// 	children: [],
	// },
	// {
	// 	title: " Summary",
	// 	route: "apps-company-hatchery-summary",
	// 	icon: "UsersIcon",
	// 	roles: ["super_admin"],
	// },
	{
		title: "Roles",
		route: "apps-roles",
		icon: "UsersIcon",
		roles: ["super_admin"],
	},
	{
		title: "Permission",
		route: "apps-permission",
		icon: "UsersIcon",
		roles: ["super_admin"],
	},
];

// router.beforeEach((to, from, next) => {
// 	if (to.matched.some((data) => data.meta.permissions)) {
// 		if (record.meta.permissions) {
// 		}
// 	}
// });
