import router from "@/router";
import ProjectIcon from "@core/components/project-icon/ProjectIcon.vue";

export default [
	{
		title: "Master Entry",
		icon: "FileIcon",
		roles: ["owner"],
		children: [
			{
				title: "Master Data",
				route: "apps-master-data",
			},
			{
				title: "Schedule",
				route: "apps-master-schedule",
			},
		],
	},
	{
		title: "Users",
		icon: "UserIcon",
		roles: ["owner"],
		children: [
			// {
			// 	title: "Owner",
			// 	route: "apps-owner-list",
			// 	roles: ["super_admin"],
			// },
			{
				title: "Admin",
				route: "apps-admin-list",
				roles: ["owner"],
			},
			{
				title: "Manager",
				route: "apps-manager-list",
				roles: ["owner"],
			},
			{
				title: "AssistantManager",
				route: "apps-assistant-list",
				roles: ["owner"],
			},
			{
				title: "Supervisor",
				route: "apps-supervisor-list",
				roles: ["owner"],
			},
		],
	},
	// {
	// 	title: "Purchase",
	// 	route: "apps-purchase",
	// 	icon: "CalendarIcon",
	// 	roles: ["admin", "owner"],
	// },
	// {
	// 	title: "Inventory",
	// 	route: "apps-inventory",
	// 	icon: "InfoIcon",
	// 	roles: ["admin", "owner"],
	// },
	// {
	// 	title: "Expense Entry",
	// 	route: "apps-expense-list",
	// 	icon: "UsersIcon",
	// 	roles: ["admin", "owner"],
	// },
	// {
	// 	title: "Current Batch",
	// 	route: "",
	// 	icon: "CalendarIcon",
	// },
	// {
	// 	title: "Action list",
	// 	route: "apps-action-list",
	// 	icon: "CalendarIcon",
	// 	roles: ["admin", "owner"],
	// },
	// {
	// 	title: "Alarm",
	// 	route: "apps-alarm",
	// 	icon: "BellIcon",
	// 	roles: ["admin", "owner"],
	// },
	{
		title: "Hatchery",
		route: "apps-hatchery-list",
		icon: "UsersIcon",
		roles: ["owner"],
	},
	// {
	// 	title: "Machine",
	// 	icon: "FileIcon",
	// 	roles: ["admin", "owner"],
	// 	children: [
	// 		{
	// 			title: "Setter",
	// 			route: "apps-machine-setter",
	// 		},
	// 		{
	// 			title: "Hatcher",
	// 			route: "apps-machine-hatcher",
	// 		},
	// 	],
	// },
	// {
	// 	title: "Hatch Summary",
	// 	route: "apps-hatchSummary-list",
	// 	icon: "CalendarIcon",
	// 	roles: ["admin", "owner"],
	// },
	// {
	//   title: "Hatch Summary",
	//   icon: "FileIcon",
	//   children: [
	//     {
	//       title: "Setter",
	//       route: "apps-hatchSummary-setter-list",
	//     },
	//     {
	//       title: "Hatcher",
	//       route: "apps-hatchSummary-hatcher-list",
	//     },
	//   ],
	// },
	// {
	// 	title: "Reports",
	// 	route: "apps-reports",
	// 	icon: "CalendarIcon",
	// 	roles: ["admin", "owner"],
	// },
	// {
	// 	title: "Sale",
	// 	icon: "CalendarIcon",
	// 	roles: ["admin", "owner"],
	// 	children: [
	// 		{
	// 			title: "Post Hatch Mortality",
	// 			route: "apps-sale-hatch-mortality",
	// 		},
	// 		{
	// 			title: "Chick Sale",
	// 			route: "apps-sale-chick-sale",
	// 		},
	// 		{
	// 			title: "Other Sale",
	// 			route: "apps-sale-other-sale",
	// 		},
	// 	],
	// },
	{
		title: "Roles",
		route: "apps-roles",
		icon: "UsersIcon",
		roles: ["owner"],
	},
	{
		title: "Permission",
		route: "apps-permission",
		icon: "UsersIcon",
		roles: ["owner"],
	},
	// {
	// 	title: "Hatchery Settings",
	// 	route: "apps-settings-hatchery-list",
	// 	icon: "UsersIcon",
	// 	roles: ["admin", "owner"],
	// },
];

// router.beforeEach((to, from, next) => {
// 	if (to.matched.some((data) => data.meta.permissions)) {
// 		if (record.meta.permissions) {
// 		}
// 	}
// });
