export default [
	// {
	//   title: 'Dashboards',
	//   icon: 'HomeIcon',
	//   tagVariant: 'light-warning',
	//   children: [
	//     {
	//       title: 'eCommerce',
	//       route: 'dashboard-ecommerce',
	//     },
	//     {
	//       title: 'Analytics',
	//       route: 'dashboard-analytics',
	//     },
	//   ],
	// },
	{
		title: "Dashboard",
		route: "hatchery-dashboard-ecommerce",
		icon: "HomeIcon",
	},
];
